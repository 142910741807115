import { DDConfigData } from './logger';

interface Config extends DDConfigData {
  mixpanelApiKey: string;
}

export default async (): Promise<Config> => {
  const res = await fetch('/api/config', {
    method: 'GET',
    credentials: 'same-origin',
  });
  return await res.json();
};
