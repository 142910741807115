/* eslint-disable no-console */
// TYPES

export interface DDConfigData {
  datadogClientToken: string;
  systemCode: string;
  environment: string;
}

type Status = 'info' | 'error' | 'debug' | 'warn';

class Logger {
  _initialised: boolean = false;

  get initialised() {
    return this._initialised;
  }

  init({ datadogClientToken, systemCode, environment }: DDConfigData) {
    try {
      if (!datadogClientToken) {
        return;
      }
      window['DD_LOGS'].init({
        datadogClientToken,
        forwardErrorsToLogs: true,
      });
      window['DD_LOGS'].addLoggerGlobalContext('env', environment);
      window['DD_LOGS'].addLoggerGlobalContext('service', systemCode);
      this._initialised = true;
    } catch (e) {
      console.error(e);
    }
  }

  log(message: string, logPayload = {}, status: Status = 'info') {
    try {
      if (!window['DD_LOGS'] || !this.initialised) {
        console[status](message, logPayload);
        return;
      }
      window['DD_LOGS'].logger.log(message, logPayload, status);
    } catch (e) {
      console.error(e);
    }
  }

  debug(message: string, logPayload = {}) {
    this.log(message, logPayload, 'debug');
  }

  info(message: string, logPayload = {}) {
    this.log(message, logPayload, 'info');
  }

  warn(message: string, logPayload = {}) {
    this.log(message, logPayload, 'warn');
  }

  error(message: string, logPayload = {}) {
    this.log(message, logPayload, 'error');
  }
}

export default new Logger();
