import styled from 'styled-components';
import {
  space,
  SpaceProps,
  color,
  ColorProps,
  layout,
  LayoutProps,
  flexbox,
  FlexboxProps,
  shadow,
  ShadowProps,
  border,
  BorderProps,
  grid,
  GridProps as IGridProps,
  position,
  PositionProps,
} from 'styled-system';

interface BoxProps
  extends SpaceProps,
    ColorProps,
    LayoutProps,
    FlexboxProps,
    ShadowProps,
    BorderProps,
    PositionProps {}

export const Box = styled.div<BoxProps>(
  {
    boxSizing: 'border-box',
  },
  space,
  color,
  layout,
  flexbox,
  shadow,
  border,
  position,
);

Box.defaultProps = {
  minWidth: 0,
};

interface FlexProps extends BoxProps, FlexboxProps {}

export const Flex = styled(Box)<FlexProps>(
  {
    display: 'flex',
  },
  flexbox,
);

interface GridProps extends BoxProps, IGridProps {}

export const Grid = styled(Box)<GridProps>(grid);
