import { Howl, Howler } from 'howler';

export interface HowlerPlayOption {
  onLoad?: () => void;
  onPlay?: (howlId: number) => void;
  onPause?: (howlId: number) => void;
  onSeek?: (howlId: number) => void;
  onEnd?: (howlId: number) => void;
  onStop?: (howlId: number) => void;
  onPlayError?: (howlId: number, error: Error) => void;
  onLoadError?: (howlId: number, error: Error) => void;
}

export interface IHowlerPlayer {
  play: (trackPath: string, options: HowlerPlayOption) => number;
  pause: (howlId: number) => void;
  resume: (howlId: number) => void;
  seek: (howlId: number, position: number) => void;
  stop: (howlId: number) => void;
  on: (howlId: number, event: string, fn: any) => void;
  getCurrentTime: (howlId: number) => number;
  getDuration: (howlId: number) => number;
  setVolume: (newVolume: number) => void;
  stopAll: () => void;
}

class HowlerPlayer implements IHowlerPlayer {
  tracksPlaying: { [index: number]: Howl } = {};

  play(trackPath: string, options: HowlerPlayOption = {}): number {
    const sound = new Howl({
      src: [trackPath],
      html5: true,
      preload: true,
      onload: options.onLoad,
      onplay: options.onPlay,
      onpause: options.onPause,
      onseek: options.onSeek,
      onend: options.onEnd,
      onstop: options.onStop,
      onloaderror: options.onLoadError,
      onplayerror: options.onPlayError,
    });

    const howlId = sound.play();
    this.tracksPlaying[howlId] = sound;
    return howlId;
  }

  pause(howlId: number) {
    const track = this.tracksPlaying[howlId];
    if (track) {
      track.pause(howlId);
    } else {
      throw new Error(`No track with id ${howlId} playing`);
    }
  }

  resume(howlId: number) {
    const track = this.tracksPlaying[howlId];
    if (track) {
      track.play(howlId);
    } else {
      throw new Error(`No track with id ${howlId} playing`);
    }
  }

  seek(howlId: number, position: number) {
    const track = this.tracksPlaying[howlId];
    if (track) {
      track.seek(position);
    } else {
      throw new Error(`No track with id ${howlId} playing`);
    }
  }

  stop(howlId: number) {
    const track = this.tracksPlaying[howlId];
    if (track) {
      track.off();
      track.stop();
      track.unload();
      delete this.tracksPlaying[howlId];
    } else {
      throw new Error(`No track with id ${howlId} playing`);
    }
  }

  on(howlId: number, event: string, fn: any) {
    const track = this.tracksPlaying[howlId];
    if (track) {
      return track.on(event, fn);
    }

    throw new Error(`No track with id ${howlId} playing`);
  }

  getCurrentTime(howlId: number) {
    const track = this.tracksPlaying[howlId];
    if (track) {
      return track.seek() as number;
    }

    throw new Error(`No track with id ${howlId} playing`);
  }

  getDuration(howlId: number) {
    const track = this.tracksPlaying[howlId];
    if (track) {
      return track.duration();
    }

    throw new Error(`No track with id ${howlId} playing`);
  }

  setVolume = (newVolume: number) => {
    const floatVolume = newVolume / 100;

    Howler.volume(floatVolume);
  };

  stopAll = () => {
    Howler.unload();
  };
}

export default new HowlerPlayer();
