import React from 'react';

export interface ITrackingContext<T = {}, E = T> {
  trackEvent: (data: E) => void;
  track: (data: T) => void;
  trackingData: T;
}

export default React.createContext<ITrackingContext>({
  trackingData: {},
  track: () => {},
  trackEvent: () => {
    return Promise.resolve();
  },
});
