import React from 'react';
import { colours, KobaltLogo } from '@ktech/kobalt-components';

import { Box } from '../layout';
import { HIDE_ON_MOBILE, SHOW_ON_MOBILE } from '../theme';

const LOGO_SIZE_DESKTOP = 64;
const LOGO_SIZE_MOBILE = 48;

interface KobaltLogoProps {
  mx?: string | number;
}

const KobaltLogoWrapper: React.FC<KobaltLogoProps> = ({ mx }) => (
  <>
    <Box mx={mx} width={LOGO_SIZE_DESKTOP} display={HIDE_ON_MOBILE}>
      <KobaltLogo
        block
        size={LOGO_SIZE_DESKTOP}
        fill={colours.white}
        bgFill={colours.red}
      />
    </Box>
    <Box mx={mx} width={LOGO_SIZE_MOBILE} display={SHOW_ON_MOBILE}>
      <KobaltLogo
        block
        size={LOGO_SIZE_MOBILE}
        fill={colours.white}
        bgFill={colours.red}
      />
    </Box>
  </>
);

KobaltLogoWrapper.defaultProps = {
  mx: 'auto',
};

export default KobaltLogoWrapper;
