import React from 'react';

import { Flex } from '../../layout';

import { useAudioPlayer, AudioPlayerPlayingState } from '../../../audioPlayer';

import TrackListTableHeaders from './TrackListTableHeaders';
import TrackListTableRow, { PromotionRecording } from './TrackListTableRow';

export interface PromotionTrackTableProps {
  recordings: PromotionRecording[];
}

const PromotionTrackTable: React.FC<PromotionTrackTableProps> = ({
  recordings,
}) => {
  const {
    playerState,
    activeRecording,
    handlePlayRecording,
    handlePauseRecording,
  } = useAudioPlayer();

  return (
    <Flex
      width={1}
      flexDirection="row"
      flexWrap="wrap"
      data-qa-id="promotion-recordings"
    >
      <TrackListTableHeaders />
      {recordings.map(recording => {
        const isActiveTrack = recording.uuid === activeRecording?.uuid;
        let handleOnClick = handlePauseRecording;

        const handlePlayRecordingBound = () =>
          handlePlayRecording({
            uuid: recording.uuid,
            artistName: recording.artistName,
            recordingTitle: recording.recordingTitle,
            versionTitle: recording.versionTitle,
            audio: recording.audio!,
          });

        if (!recording.hasAudio) {
          handleOnClick = () => {};
        } else if (!isActiveTrack) {
          handleOnClick = handlePlayRecordingBound;
        } else if (
          playerState === AudioPlayerPlayingState.error ||
          playerState === AudioPlayerPlayingState.inactive
        ) {
          handleOnClick = handlePlayRecordingBound;
        }

        return (
          <TrackListTableRow
            key={recording.uuid}
            {...recording}
            isActiveTrack={isActiveTrack}
            playingState={playerState}
            handleOnClick={handleOnClick}
          />
        );
      })}
    </Flex>
  );
};

export default PromotionTrackTable;
