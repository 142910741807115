import React from 'react';

import { Box, Background, KobaltTransparentLogo } from '../../components';

import backgroundImage from './404.jpg';
import { Heading, H3 } from '@ktech/kobalt-components';

const NoMatch: React.FC = () => {
  return (
    <>
      <Box>
        <KobaltTransparentLogo />
      </Box>
      <Background
        backgroundImage={`url(${backgroundImage})`}
        backgroundSize="cover"
        backgroundPosition="center"
        width="100%"
        height="100%"
        position="fixed"
        top={0}
        zIndex={-1}
        data-qa-id="404-message"
      />
      <Box mx="auto" mt="15%" maxWidth={768} px={4}>
        <Heading colour="white">Is it too late now to say sorry?</Heading>
        <H3 colour="white">
          We couldn’t find the page you were looking for, please try again or
          get in touch with your Kobalt contact.
        </H3>
      </Box>
    </>
  );
};

export default NoMatch;
