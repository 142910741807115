import mixpanel, { Config, Dict } from 'mixpanel-browser';
import logger from '../logger';

const PRODUCT_NAME = 'sync_pitch_delivery';
const PRODUCT_VERSION = 'v1';
const PAGE_VIEW = 'Page view';

const COMMON_PROPERTIES = {
  product_name: PRODUCT_NAME,
  product_version: PRODUCT_VERSION,
};

class MixPanel {
  private _isInit?: boolean = false;
  private _token?: string;

  private emitTracking(
    eventName: string,
    properties?: Dict,
    callback?: () => void,
  ) {
    if (this._token) {
      mixpanel.track(
        eventName,
        { ...COMMON_PROPERTIES, ...properties },
        callback,
      );
    } else {
      logger.info(`Tracking: ${eventName}`, properties);
    }
  }

  init(token: string, config?: Partial<Config>, name?: string) {
    if (this._isInit) {
      throw new Error(`Mixpanel has already been initialize`);
    }

    if (token) {
      mixpanel.init(token, config, name);
      this._token = token;
    }

    this._isInit = true;
  }

  track(eventName: string, properties?: Dict, callback?: () => void) {
    if (!this._isInit) {
      throw new Error(`Mixpanel not initialize, call init before track`);
    }
    this.emitTracking(
      eventName,
      { ...COMMON_PROPERTIES, ...properties },
      callback,
    );
  }

  page(pageName: string, properties?: Dict, callback?: () => void) {
    if (!this._isInit) {
      throw new Error(`Mixpanel not initialize, call init before page`);
    }

    this.emitTracking(
      PAGE_VIEW,
      {
        ...COMMON_PROPERTIES,
        ...properties,
        page_name: pageName,
      },
      callback,
    );
  }
}

export default new MixPanel();
