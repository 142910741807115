import React from 'react';
import { Previous as PrevIcon, IconButton } from '@ktech/kobalt-components';
import { colours } from '@ktech/kobalt-components/dist/abstracts/colours';

import { ClickableButtonProps } from './types/ClickableButtonProps';

export const PreviousButton: React.FC<ClickableButtonProps> = ({
  handleClick,
  isDisabled,
}) => (
  <IconButton
    onClick={handleClick}
    data-qa-id="audio-player-back"
    disabled={isDisabled}
  >
    <PrevIcon fill={isDisabled ? colours.silver : colours.navy} size={16} />
  </IconButton>
);

export default React.memo(PreviousButton);
