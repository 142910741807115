import React, { useEffect } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';

import Loading from '../../components/loading/Loading';
import Error from '../../components/error/';
import NoMatch from '../noMatch';

import { PromotionPage } from '../../components/promotion/';
import { useTracking } from '../../components/tracking';
import { TrackingData, TrackingEventData } from '../../tracking/types';

import { PromotionRecording } from '../../components/promotion/trackTable/TrackListTableRow';

export const PROMOTION_QUERY = gql`
  query promotion($uuid: ID!) {
    promotion(uuid: $uuid) {
      uuid
      clientProject
      description
      archiveUrl
      recordings {
        uuid
        recordingTitle
        versionTitle
        albumTitle
        hasAudio
        isExplicit
        audio {
          uuid
          streamUrl
          downloadUrl
        }
        artist {
          databaseId
          name
        }
        artistName
      }
    }
  }
`;

interface Promotion {
  uuid: string;
  clientProject: string;
  description: string;
  archiveUrl: string;
  recordings: PromotionRecording[];
}

interface PromotionData {
  promotion: Promotion;
}

interface PromotionData {
  promotion: Promotion;
}

interface PromotionVariables {
  uuid?: string;
}

const Promotion: React.FC = () => {
  const { uuid } = useParams();
  const [{ trackEvent }, trackMetadata] = useTracking<
    TrackingData,
    TrackingEventData
  >();

  useEffect(() => {
    trackMetadata({
      promotionUuid: uuid,
    });
  }, [uuid]);

  const { loading, error, data } = useQuery<PromotionData, PromotionVariables>(
    PROMOTION_QUERY,
    { variables: { uuid } },
  );

  if (loading) return <Loading showTrackRows={true} uuid={uuid} />;
  if (error) return <Error />;
  if (!data || !data.promotion) return <NoMatch />;

  const { clientProject, archiveUrl, recordings } = data.promotion;

  trackEvent({
    eventType: 'page',
    eventName: 'PromotionPageViewed',
    page: 'promotion',
  });

  return (
    <>
      <PromotionPage
        uuid={uuid!}
        clientProject={clientProject}
        archiveUrl={archiveUrl}
        recordings={recordings}
      />
    </>
  );
};

export default Promotion;
