import React, { useState } from 'react';
import {
  Slider as SliderComponent,
  SliderHandle as Handle,
} from '@ktech/kobalt-components';

interface VolumeSliderProps {
  volume: number;
  handleVolumeChanged: (volume: number) => void;
  handleVolumeSeek: (volume: number) => void;
}

const VolumeSlider: React.FC<VolumeSliderProps> = ({
  volume,
  handleVolumeChanged,
  handleVolumeSeek,
}) => {
  return (
    <SliderComponent
      data-qa-id={`volume-slider-${volume}`}
      value={[0, volume]}
      onChange={values => {
        if (values) {
          const [_, newVol] = values;
          handleVolumeChanged(newVol);
        }
      }}
      inputProps={{
        handleSize: 16,
        from: 0,
        to: 100,
        step: 1,
        formatHandleValue: _ => '', // Yea we don't want these as they're hard to control
      }}
    >
      {handles => {
        // TODO: I mean WTF
        const [firstHandle] = handles;
        firstHandle.disabled = true; // eslint-disable-line no-param-reassign
        return handles.map((props, i) => {
          return (
            <Handle
              {...props}
              key={i}
              inputProps={{
                ...props.inputProps,
                onMove: (value: number, perc: number) => {
                  if (props.inputProps.onMove) {
                    props.inputProps.onMove(value, perc);
                  }
                  handleVolumeSeek(value);
                },
              }}
            />
          );
        });
      }}
    </SliderComponent>
  );
};

export default React.memo(VolumeSlider);
