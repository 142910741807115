import React from 'react';

import { colours, H1 } from '@ktech/kobalt-components';

import { Box, Flex } from '../../components';
import { DownloadAllTracksButton } from '.';
import { HIDE_ON_MOBILE } from '../theme';

interface PromotionHeaderProps {
  title?: string;
  archiveUrl?: string;
}

const PromotionHeader: React.FC<PromotionHeaderProps> = ({
  title = '',
  archiveUrl,
}) => {
  return (
    <Box maxWidth={1024} flexDirection="row" mx="auto">
      <Box width={1} height={24} mt={[4, 5]} mb={[2, 4]}>
        {title && (
          <H1
            align="center"
            colour={colours.white}
            data-qa-id="promotion-client-project"
          >
            {title}
          </H1>
        )}
      </Box>
      <Flex height={48} width={1} mb={[3, 5]}>
        {archiveUrl && title && (
          <Box mx="auto" width="auto" display={HIDE_ON_MOBILE}>
            <DownloadAllTracksButton downloadUrl={archiveUrl} />
          </Box>
        )}
      </Flex>
    </Box>
  );
};

export default React.memo(PromotionHeader);
