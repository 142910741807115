import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { colours } from '@ktech/kobalt-components';

import { Flex, Box } from '../../../layout';
import { HIDE_ON_TABLET } from '../../../theme';

import { trackTableRowWidths, TRACK_ROW_HEIGHT } from '../constants';

const LoadingTrackListTableRow: React.FC = () => {
  return (
    <Box width={1}>
      <Flex
        flexDirection="row"
        width={1}
        borderTop={`solid 1px ${colours.aquaHaze}`}
        alignItems="center"
        height={TRACK_ROW_HEIGHT}
      >
        <Box width={trackTableRowWidths.play} />
        {/* Inner flex needed for mobile  */}
        <Flex
          flexWrap={['wrap', 'nowrap']}
          alignItems="center"
          width={[1, 'auto']}
        >
          <Box width={trackTableRowWidths.track} order={1}>
            <Skeleton />
          </Box>
          <Box width={trackTableRowWidths.explicit} order={[3, 2]} />
          <Box width={trackTableRowWidths.artist} order={[2, 3]} mr={3}>
            <Skeleton />
          </Box>
        </Flex>
        <Box width={trackTableRowWidths.download} mx={3}>
          <Skeleton />
        </Box>
      </Flex>
    </Box>
  );
};

export default LoadingTrackListTableRow;
