import React from 'react';

import TrackingContext from './TrackingContext';

interface ITrackingProviderProps<T> {
  trackEvent: (td: T) => void;
}

export default class TrackingProvider<T = {}> extends React.Component<
  ITrackingProviderProps<T>,
  T
> {
  render() {
    const { trackEvent, children } = this.props;

    return (
      <TrackingContext.Provider
        value={{
          trackingData: this.state,
          track: td => this.setState(td),
          trackEvent: td => {
            trackEvent({
              ...td,
              ...this.state,
            });
          },
        }}
      >
        {children}
      </TrackingContext.Provider>
    );
  }
}
