import {
  AudioPlayerActiveRecording,
  AudioPlayerInternalState,
  AudioPlayerRecording,
} from './types';

export interface AudioPlayerState {
  muted: boolean;
  volume: number;
  playerState: AudioPlayerInternalState;
  activeRecording?: AudioPlayerActiveRecording;
  howlId?: number;
}

export enum AudioPlayerActionTypes {
  play = 'play',
  playing = 'playing',
  played = 'played',
  playingProgress = 'playingProgress',
  pause = 'pause',
  paused = 'paused',
  stop = 'stop',
  errored = 'errored',
  muteToggled = 'muteToggled',
  volumeChanged = 'volumeChanged',
}

export type AudioPlayerActions =
  | {
      type: AudioPlayerActionTypes.play;
      payload: { recording: AudioPlayerRecording };
    }
  | {
      type: AudioPlayerActionTypes.playing;
      payload: { howlId: number; audioDuration: number };
    }
  | {
      type: AudioPlayerActionTypes.playingProgress;
      payload: { audioSeekPosition: number };
    }
  | {
      type: AudioPlayerActionTypes.played;
    }
  | {
      type: AudioPlayerActionTypes.pause;
    }
  | {
      type: AudioPlayerActionTypes.paused;
    }
  | {
      type: AudioPlayerActionTypes.stop;
    }
  | {
      type: AudioPlayerActionTypes.errored;
    }
  | {
      type: AudioPlayerActionTypes.muteToggled;
    }
  | {
      type: AudioPlayerActionTypes.volumeChanged;
      payload: { volume: number };
    };

export const initialAudioPlayerState: AudioPlayerState = {
  playerState: AudioPlayerInternalState.stopped,
  activeRecording: undefined,
  howlId: undefined,
  muted: false,
  volume: 100,
};

export default function audioPlayerReducer(
  prevState: AudioPlayerState,
  action: AudioPlayerActions,
): AudioPlayerState {
  switch (action.type) {
    case AudioPlayerActionTypes.play:
      return {
        ...prevState,
        playerState: AudioPlayerInternalState.loading,
        activeRecording: action.payload.recording,
        howlId: undefined,
      };
    case AudioPlayerActionTypes.playing:
      return {
        ...prevState,
        playerState: AudioPlayerInternalState.playing,
        howlId: action.payload.howlId,
        activeRecording: prevState.activeRecording
          ? {
              ...prevState.activeRecording,
              audioDuration: action.payload.audioDuration,
            }
          : undefined,
      };
    case AudioPlayerActionTypes.playingProgress:
      return {
        ...prevState,
        activeRecording: prevState.activeRecording
          ? {
              ...prevState.activeRecording,
              audioSeekPosition: action.payload.audioSeekPosition,
            }
          : undefined,
      };
    case AudioPlayerActionTypes.played:
      return {
        ...prevState,
        playerState: AudioPlayerInternalState.played,
        activeRecording: undefined,
        howlId: undefined,
      };
    case AudioPlayerActionTypes.stop:
      return {
        ...prevState,
        playerState: AudioPlayerInternalState.stopped,
        activeRecording: undefined,
        howlId: undefined,
      };
    case AudioPlayerActionTypes.pause:
      return {
        ...prevState,
        playerState: AudioPlayerInternalState.pausing,
      };
    case AudioPlayerActionTypes.paused:
      return {
        ...prevState,
        playerState: AudioPlayerInternalState.paused,
      };
    case AudioPlayerActionTypes.errored:
      return {
        ...prevState,
        playerState: AudioPlayerInternalState.errored,
      };
    case AudioPlayerActionTypes.muteToggled:
      return {
        ...prevState,
        muted: !prevState.muted,
      };
    case AudioPlayerActionTypes.volumeChanged:
      if (action.payload.volume === 0) {
        return {
          ...prevState,
          muted: true,
        };
      }

      return {
        ...prevState,
        volume: action.payload.volume,
        muted: false,
      };
  }
}
