import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import gql from 'graphql-tag';

import Promotion from './routes/promotion';
import NoMatch from './routes/noMatch';

import Loading from './components/loading/Loading';
import Error from './components/error/';
import CookieConsent from './components/cookieConsent';
import { useTracking } from './components/tracking';
import { TrackingEventData, TrackingData } from './tracking/types';

export const CURRENT_USER = gql`
  {
    currentUser {
      uuid
    }
  }
`;

interface AppProps {
  uuid?: string; // used to lock loading screen image
}

const App: React.FC<AppProps> = ({ uuid }) => {
  const { data, loading, error } = useQuery(CURRENT_USER);
  const [, trackMetadata] = useTracking<TrackingData, TrackingEventData>();

  const currentUserUuid =
    data && data.currentUser ? data.currentUser.uuid : undefined;

  useEffect(() => {
    trackMetadata({
      receiverUuid: currentUserUuid,
    });
  }, [currentUserUuid]);

  if (loading) return <Loading uuid={uuid} />;
  if (error) return <Error />;

  // No user! Not going to show you anything
  if (!currentUserUuid) {
    return <NoMatch />;
  }

  return (
    <div data-qa-id="sync-pitch-delivery-app">
      <Router>
        <Switch>
          <Route path="/promotion/:uuid" children={<Promotion />} />
          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
      </Router>
      <CookieConsent />
    </div>
  );
};

export default App;
