import React from 'react';
import { Helmet } from 'react-helmet';

import { KobaltTransparentLogo, PolicyFooter } from '../../components';
import {
  PromotionBackground,
  PromotionTrackTable,
  TrackContainer,
  PromotionHeader,
} from './index';

import {
  AudioPlayerProvider,
  audioPlayerReducer,
  initialAudioPlayerState,
} from '../../audioPlayer';

import { TrackingData, TrackingEventData } from '../../tracking';
import { useTracking } from '../tracking';

import PromotionAudioPlayer from './AudioPlayer';
import { PromotionRecording } from './trackTable/TrackListTableRow';

interface PromotionPageProps {
  uuid: string;
  clientProject: string;
  archiveUrl: string;
  recordings: PromotionRecording[];
}

const mapRecordingToAudioPlayerRecording = (recording?: PromotionRecording) => {
  if (!recording) {
    return undefined;
  }

  return {
    uuid: recording.uuid,
    artistName: recording.artistName,
    recordingTitle: recording.recordingTitle,
    versionTitle: recording.versionTitle,
    audio: recording.audio!,
  };
};

const PromotionPage: React.FC<PromotionPageProps> = ({
  uuid,
  clientProject,
  archiveUrl,
  recordings,
}) => {
  const [{ trackEvent }] = useTracking<TrackingData, TrackingEventData>();

  const [audioPlayerState, dispatch] = React.useReducer(
    audioPlayerReducer,
    initialAudioPlayerState,
  );

  const { activeRecording } = audioPlayerState;

  const recordingsWithAudio = recordings.filter(r => r.hasAudio);

  let nextRecording;
  let prevRecording;
  if (activeRecording) {
    const activeTrackIndex = recordingsWithAudio.findIndex(
      r => r.uuid === activeRecording.uuid,
    );

    nextRecording = mapRecordingToAudioPlayerRecording(
      recordingsWithAudio[activeTrackIndex + 1],
    );
    prevRecording = mapRecordingToAudioPlayerRecording(
      recordingsWithAudio[activeTrackIndex - 1],
    );
  }

  return (
    <>
      <Helmet>
        <title>Kobalt Music | {clientProject}</title>
      </Helmet>
      <PromotionBackground uuid={uuid} />
      <KobaltTransparentLogo />

      <AudioPlayerProvider
        state={audioPlayerState}
        dispatch={dispatch}
        trackEvent={(name, payload) => {
          // contract of the providers track event is
          // simpler so we wrap the extra complexity of
          // event type here making an interface which makes more sense
          // to the audio player provider
          trackEvent({
            eventType: 'track',
            eventName: name,
            ...payload,
          });
        }}
        previousRecording={prevRecording}
        nextRecording={nextRecording}
      >
        <div data-qa-id={`promotion-${uuid}`}>
          <PromotionHeader title={clientProject} archiveUrl={archiveUrl} />
          <TrackContainer>
            <PromotionTrackTable recordings={recordings} />
          </TrackContainer>
          <PolicyFooter />
          <PromotionAudioPlayer />
        </div>
      </AudioPlayerProvider>
    </>
  );
};

export default PromotionPage;
