import React from 'react';
import { Heading, H3 } from '@ktech/kobalt-components';

import { Box, Background, KobaltTransparentLogo } from '../../components';
import backgroundImage from './error.jpg';

interface ErrorProps {
  dataQaId?: string;
}

const Error: React.FC<ErrorProps> = ({ dataQaId = 'error' }) => (
  <>
    <Box>
      <KobaltTransparentLogo />
    </Box>
    <Background
      backgroundImage={`url(${backgroundImage})`}
      backgroundSize="cover"
      backgroundPosition="center"
      width="100%"
      height="100%"
      position="fixed"
      top={0}
      zIndex={-1}
      data-qa-id="404-message"
    />
    <Box mx="auto" mt="15%" maxWidth={768} px={4} data-qa-id={dataQaId}>
      <Heading colour="white">Sorry, something went wrong on our end</Heading>
      <H3 colour="white">
        Please try again or get in touch with your Kobalt contact if the issue
        persists
      </H3>
    </Box>
  </>
);

export default Error;
