import React from 'react';
import styled from 'styled-components';

import { LinkButton } from '@ktech/kobalt-components';

const LinkButtonWithoutTextDec = styled(LinkButton)`
  text-decoration: none;
  text-transform: capitalize;
`;

interface PolicyLinkProps {
  href: string;
  text: string;
}

const PolicyLink: React.FC<PolicyLinkProps> = ({ href, text }) => {
  return (
    <LinkButtonWithoutTextDec
      as="a"
      href={href}
      buttonSize="small"
      target="_blank"
    >
      {text}
    </LinkButtonWithoutTextDec>
  );
};

export default PolicyLink;
