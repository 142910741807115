import React from 'react';
import styled from 'styled-components';
import {
  Loader,
  colours,
  Warning as WarningIcon,
  Download as DownloadIcon,
} from '@ktech/kobalt-components';
import { useTracking } from '../../../components/tracking';
import { Flex, Box } from '../../../components';
import { TrackingData, TrackingEventData } from '../../../tracking/types';

const Link = styled(Box)`
  &:hover {
    cursor: pointer;
  }
`;

interface DownloadTrackProps {
  uuid: string;
  downloadUrl: string;
}

const DownloadTrackLink: React.FC<DownloadTrackProps> = ({
  uuid,
  downloadUrl,
}) => {
  const [{ trackEvent }] = useTracking<TrackingData, TrackingEventData>();

  return (
    <a href={downloadUrl} download>
      <Link
        onClick={() => {
          trackEvent({
            eventType: 'track',
            eventName: 'PromotionRecordingDownloadFromAudioPlayer',
            recordingUuid: uuid,
          });
        }}
        data-qa-id={`audio-player-${uuid}-download`}
      >
        <Flex alignItems="center">
          <Box>
            <DownloadIcon size={22} fill={colours.blue} />
          </Box>
        </Flex>
      </Link>
    </a>
  );
};

export default React.memo(DownloadTrackLink);
