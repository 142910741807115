import React from 'react';

import { Flex } from '../../../layout';
import TrackListTableHeaders from '../TrackListTableHeaders';
import LoadingTrackListTableRow from './LoadingTrackListTableRow';

const LoadingPromotionTrackTable: React.FC = () => {
  const loadingRows = Math.floor(Math.random() * 7);

  const rows = [];

  for (let i = 1; i < loadingRows; i++) {
    rows.push(<LoadingTrackListTableRow key={i} />);
  }

  return (
    <Flex
      width={1}
      flexDirection="row"
      flexWrap="wrap"
      data-qa-id="promotion-recordings-loading"
    >
      <TrackListTableHeaders />
      {rows}
    </Flex>
  );
};

export default LoadingPromotionTrackTable;
