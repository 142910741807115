import React from 'react';
import AudioPlayerContext, { IAudioPlayerContext } from './AudioPlayerContext';
import {
  AudioPlayerPlayingState,
  AudioPlayerRecording,
  AudioPlayerInternalState,
  AudioPlayerActiveRecording,
} from './types';

interface UseAudioPlayer {
  playerState: AudioPlayerPlayingState;
  audioPlayerVisible: boolean;
  activeRecording?: AudioPlayerActiveRecording;
  volume: number;
  muted: boolean;
  handlePlayRecording: (recording: AudioPlayerRecording) => void;
  handlePlayNextRecording?: () => void;
  handlePlayPreviousRecording?: () => void;
  handleSeekRecordingStarted: () => void;
  handleSeekRecordingEnded: (position: number) => void;
  handlePauseRecording: () => void;
  handleStopRecording: () => void;
  handleToggleMute: () => void;
  handleVolumeChanged: (volume: number) => void;
  handleVolumeSeek: (volume: number) => void;
}

const mapInternalToExternalPlayerState = {
  [AudioPlayerInternalState.stopped]: AudioPlayerPlayingState.inactive,
  [AudioPlayerInternalState.loading]: AudioPlayerPlayingState.loading,
  [AudioPlayerInternalState.playing]: AudioPlayerPlayingState.playing,
  [AudioPlayerInternalState.played]: AudioPlayerPlayingState.inactive,
  [AudioPlayerInternalState.pausing]: AudioPlayerPlayingState.playing,
  [AudioPlayerInternalState.paused]: AudioPlayerPlayingState.inactive,
  [AudioPlayerInternalState.errored]: AudioPlayerPlayingState.error,
};

export default function useAudioPlayer(): UseAudioPlayer {
  const context = React.useContext<IAudioPlayerContext>(AudioPlayerContext);

  if (!context) {
    throw new Error(
      'Attempting to call `useAudioPlayer` ' +
        'without a AudioPlayerContext present.',
    );
  }

  return {
    ...context,
    playerState: mapInternalToExternalPlayerState[context.playerState],
    audioPlayerVisible:
      context.playerState !== AudioPlayerInternalState.stopped,
  };
}
