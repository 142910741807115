import React from 'react';
import styled from 'styled-components';
import {
  colours,
  Download as DownloadIcon,
  TextLine,
} from '@ktech/kobalt-components';

import { Flex, Box } from '../layout';
import { HIDE_ON_MOBILE } from '../theme';
import { useTracking } from '../tracking';
import { TrackingData, TrackingEventData } from '../../tracking/types';

const Link = styled(Box)`
  &:hover {
    cursor: pointer;
  }
`;

interface DownloadTrackLinkProps {
  uuid: string;
  downloadUrl: string;
}

const DownloadTrackLink: React.FC<DownloadTrackLinkProps> = ({
  uuid,
  downloadUrl,
}) => {
  const [{ trackEvent }] = useTracking<TrackingData, TrackingEventData>();

  return (
    <a href={downloadUrl} download>
      <Link
        onClick={() => {
          trackEvent({
            eventType: 'track',
            eventName: 'PromotionRecordingDownload',
            recordingUuid: uuid,
          });
        }}
        data-qa-id={`promotion-recording-${uuid}-download`}
      >
        <Flex alignItems="center">
          <Box pr={2} display={HIDE_ON_MOBILE}>
            <TextLine colour={colours.blue}>Download</TextLine>
          </Box>
          <Box>
            <DownloadIcon size={24} fill={colours.blue} />
          </Box>
        </Flex>
      </Link>
    </a>
  );
};

export default DownloadTrackLink;
