import React from 'react';

import { Box } from '../layout';
import { HIDE_ON_MOBILE, SHOW_ON_MOBILE } from '../theme';

const LOGO_SIZE_DESKTOP_WIDTH = 40;
const LOGO_SIZE_MOBILE = 16;
const LOGO_SIZE_MOBILE_WIDTH = 40;

interface KobaltTransparentLogoSvgProps {
  size?: number;
}

const KobaltTransparentLogoSvg: React.SFC<KobaltTransparentLogoSvgProps> = ({
  size = 24,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" 
    id="Layer_1" 
    viewBox="0 0 585 700"
    height= {size}
  >
    <defs id="style-defs">
        <style id="white-fill">{`.white-fill {fill : #fff;}`}</style>
    </defs>
    <polygon className="white-fill" points="224 294 339 294 564 0 417 0 192 294 122 294 122 0 0 0 0 700 122 700 122 358 426 700 585 700 224 294"/>
</svg>
);

interface KobaltTransparentLogoLogoProps {
  mx?: string | number;
}

const KobaltTransparentLogoWrapper: React.FC<KobaltTransparentLogoLogoProps> = ({
  mx,
}) => (
  <>
    <Box
      mx={mx}
      width={LOGO_SIZE_DESKTOP_WIDTH}
      display={HIDE_ON_MOBILE}
      pt={20}
    >
      <KobaltTransparentLogoSvg />
    </Box>
    <Box
      mx={mx}
      width={LOGO_SIZE_MOBILE_WIDTH}
      display={SHOW_ON_MOBILE}
      pt={16}
    >
      <KobaltTransparentLogoSvg size={LOGO_SIZE_MOBILE} />
    </Box>
  </>
);

KobaltTransparentLogoWrapper.defaultProps = {
  mx: 'auto',
};

export default KobaltTransparentLogoWrapper;
