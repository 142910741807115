import * as React from 'react';
import styled from 'styled-components';
import {
  Slider as SliderComponent,
  SliderHandle as Handle,
} from '@ktech/kobalt-components';

import { SliderWrapperProps } from '@ktech/kobalt-components/dist/typings/slider';

interface HandleMouseTrackerProps {
  size?: number;
}

interface MobileSliderComponentProps extends SliderWrapperProps {
  isMobile: boolean;
}

const MobileSliderComponent = styled(SliderComponent)<
  MobileSliderComponentProps
>`
  ${props => {
    if (props.isMobile) {
      return `
        margin-top: 0px;
        padding-bottom: 0px;
      `;
    }
  }}
`;

const HandleMouseTracker = styled.div<HandleMouseTrackerProps>`
  position: absolute;
  ${props => {
    if (!props.size) {
      return '';
    }

    return `
      width: ${props.size}px;
      height: ${props.size}px;
      margin-top: ${-props.size / 2}px;
      margin-left: ${-props.size / 2}px;
    `;
  }}
`;

interface SliderProps {
  'data-qa-id': string;
  seekPosition: number;
  duration: number;
  isMobile?: boolean;
  handleSeekingStarted: () => void;
  handleSeekingEnded: (value: number) => void;
  handleSeeking?: (value: number) => void;
}

const Slider: React.FC<SliderProps> = ({
  'data-qa-id': qaId,
  seekPosition,
  duration,
  handleSeekingStarted,
  handleSeekingEnded,
  handleSeeking = () => {},
  isMobile = false,
}) => {
  return (
    <MobileSliderComponent
      isMobile={isMobile}
      data-qa-id={`${qaId}-slider`}
      value={[0, seekPosition]}
      onChange={values => {
        if (values) {
          const [_, seekingPosition] = values;
          handleSeekingEnded(seekingPosition);
        }
      }}
      inputProps={{
        handleSize: 16,
        from: 0,
        to: duration,
        step: 1,
        formatHandleValue: _ => '', // Yea we don't want these as they're hard to control
      }}
    >
      {handles => {
        // TODO: I mean WTF
        const [firstHandle] = handles;
        firstHandle.disabled = true; // eslint-disable-line no-param-reassign
        return handles.map((props, i) => (
          <Handle
            {...props}
            key={i}
            inputProps={{
              ...props.inputProps,
              onMove: (value: number, perc: number) => {
                if (props.inputProps.onMove) {
                  props.inputProps.onMove(value, perc);
                }
                handleSeeking(value);
              },
            }}
          >
            <HandleMouseTracker
              size={24}
              onMouseDown={handleSeekingStarted}
              onTouchStart={handleSeekingStarted}
            />
          </Handle>
        ));
      }}
    </MobileSliderComponent>
  );
};

export default React.memo(Slider);
