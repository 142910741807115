import React from 'react';

import Background from '../background';

import headerImage1 from './headerImages/1.jpg';
import headerImage2 from './headerImages/2.jpg';
import headerImage3 from './headerImages/3.jpg';
import headerImage4 from './headerImages/4.jpg';
import headerImage5 from './headerImages/5.jpg';
import headerImage6 from './headerImages/6.jpg';
import headerImage7 from './headerImages/7.jpg';

const imageMap: { [key: number]: any } = {
  0: headerImage1,
  1: headerImage2,
  2: headerImage3,
  3: headerImage4,
  4: headerImage5,
  5: headerImage6,
  6: headerImage7,
};

interface PromotionBackgroundProps {
  uuid: string;
}

const firstIntUnderFromUuid = (uuid: string, under: number): number => {
  const values: number[] = (uuid
    .split('')
    .filter((x: any) => x < under) as unknown) as number[];

  return values[0] || 0;
};

const PromotionBackground: React.FC<PromotionBackgroundProps> = ({ uuid }) => {
  const headerNumber = firstIntUnderFromUuid(uuid, 7);
  return (
    <Background
      data-qa-id={`background-image-num-${headerNumber}`}
      backgroundImage={`url(${imageMap[headerNumber]})`}
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      width="100%"
      height={448}
      position="fixed"
      zIndex={-1}
    />
  );
};

export default PromotionBackground;
