import React from 'react';
import { Close as CloseIcon, IconButton } from '@ktech/kobalt-components';
import { colours } from '@ktech/kobalt-components/dist/abstracts/colours';
import { ClickableButtonProps } from './types/ClickableButtonProps';

const CloseButton: React.FC<ClickableButtonProps> = ({ handleClick }) => (
  <IconButton onClick={handleClick} data-qa-id="audio-player-close">
    <CloseIcon fill={colours.blue} size={14} />
  </IconButton>
);

export default React.memo(CloseButton);
