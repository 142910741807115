import { useContext } from 'react';
import TrackingContext, { ITrackingContext } from './TrackingContext';

export default function useTracking<T, E = T>(): [
  {
    trackEvent: (data: E) => void;
    trackingData: T;
  },
  (data: T) => void,
] {
  const trackingContext = useContext<ITrackingContext<T, E>>(
    TrackingContext as any,
  );

  if (!trackingContext) {
    throw new Error(
      'Attempting to call `useTracking` ' +
        'without a TrackingContext present.',
    );
  }

  return [
    {
      trackEvent: trackingContext.trackEvent,
      trackingData: trackingContext.trackingData,
    },
    trackingContext.track,
  ];
}
