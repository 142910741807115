import { TrackingEventData } from './types';
import mixpanel from './mixpanel';

export { default as mixpanel } from './mixpanel';
export * from './types';

export default (td: TrackingEventData) => {
  if (td.eventType === 'page') {
    mixpanel.page(td.page as string, {
      user_id: td.receiverUuid,
      distinct_id: td.receiverUuid,
      ...td,
    });
  } else {
    mixpanel.track(td.eventName, {
      user_id: td.receiverUuid,
      distinct_id: td.receiverUuid,
      ...td,
    });
  }
};
