export interface AudioPlayerRecording {
  uuid: string;
  recordingTitle: string;
  versionTitle?: string;
  artistName: string;
  audio: {
    streamUrl: string;
    downloadUrl: string;
  };
}

export interface AudioPlayerActiveRecording extends AudioPlayerRecording {
  audioDuration?: number;
  audioSeekPosition?: number;
}

// All internal states about loading, stopping etc
export enum AudioPlayerInternalState {
  loading = 'loading',
  playing = 'playing',
  played = 'played',
  pausing = 'pausing',
  paused = 'paused',
  errored = 'errored',
  stopped = 'stopped',
}

// Simplifed state model used to drive the UI
export enum AudioPlayerPlayingState {
  inactive = 'inactive',
  loading = 'loading',
  playing = 'playing',
  error = 'error',
}
