import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { TextLine, colours } from '@ktech/kobalt-components';

import createRecordingFilename from '../../recording/createRecordingFilename';
import { Flex, Box, FullWidthTextLine } from '../../components';

import PlayRecordingButton from './PlayRecordingButton';

import {
  AudioPlayerPlayingState,
  AudioPlayerRecording,
  AudioPlayerActiveRecording,
} from '../types';

import {
  NextButton,
  CloseButton,
  PreviousButton,
  ProgressSlider,
  MuteButton,
  VolumeSlider,
  DownloadTrack,
} from './controls';
import {
  HIDE_ON_MOBILE,
  HIDE_ON_TABLET,
  SHOW_ON_MOBILE,
} from '../../components/theme';

const TextLineRight = styled(FullWidthTextLine)`
  text-align: right;
`;

const noop = () => {};

interface AudioPlayerBarProps {
  playingState: AudioPlayerPlayingState;
  activeRecording?: AudioPlayerActiveRecording;
  isVisible?: boolean;
  muted: boolean;
  volume: number;
  height?: number;
  maxWidth?: number;
  handlePlayRecording: (recording: AudioPlayerRecording) => void;
  handleSeekRecordingStarted: () => void;
  handleSeekRecordingEnded: (seekPosition: number) => void;
  handlePauseRecording: () => void;
  handleStopRecording: () => void;
  handlePlayNext?: () => void;
  handlePlayPrev?: () => void;
  handleToggleMute: () => void;
  handleVolumeChanged: (volume: number) => void;
  handleVolumeSeek: (volume: number) => void;
}

interface ControlsProps {
  activeRecording?: AudioPlayerActiveRecording;
  buttonPlayingState: AudioPlayerPlayingState;
  handlePlayRecording: (recording: AudioPlayerRecording) => void;
  handlePauseRecording: () => void;
  handlePlayNext?: () => void;
  handlePlayPrev?: () => void;
}

const Controls: React.FC<ControlsProps> = ({
  activeRecording,
  buttonPlayingState,
  handlePlayRecording,
  handlePauseRecording,
  handlePlayNext,
  handlePlayPrev,
}) => {
  let handleOnClick = () => {};

  if (buttonPlayingState === AudioPlayerPlayingState.playing) {
    handleOnClick = handlePauseRecording;
  } else if (
    activeRecording &&
    (buttonPlayingState === AudioPlayerPlayingState.inactive ||
      buttonPlayingState === AudioPlayerPlayingState.error)
  ) {
    handleOnClick = () => handlePlayRecording(activeRecording);
  }

  return (
    <Flex flexShrink={0} ml={26} alignItems="center" justifyContent="center">
      <Box mr={14}>
        <PreviousButton
          isDisabled={!handlePlayPrev}
          handleClick={handlePlayPrev ? handlePlayPrev : noop}
        />
      </Box>
      <Box>
        <PlayRecordingButton
          size={30}
          isActiveTrack={true}
          playingState={buttonPlayingState}
          handleOnClick={handleOnClick}
          data-qa-id="audio-player-play"
        />
      </Box>
      <Box ml={14}>
        <NextButton
          isDisabled={!handlePlayNext}
          handleClick={handlePlayNext ? handlePlayNext : noop}
        />
      </Box>
    </Flex>
  );
};

interface PlayheadProps {
  activeRecordingSeekPosition: number;
  activeRecordingDuration: number;
  handleSeekRecordingStarted: () => void;
  handleSeekRecordingEnded: (seekPosition: number) => void;
}

const Playhead: React.FC<PlayheadProps> = React.memo(
  ({
    activeRecordingSeekPosition,
    activeRecordingDuration,
    handleSeekRecordingStarted,
    handleSeekRecordingEnded,
  }) => {
    const [seekingPosition, setSeekingPosition] = useState(0);
    const handleSeekingEnded = useCallback(
      value => {
        handleSeekRecordingEnded(value);
        setSeekingPosition(0);
      },
      [handleSeekRecordingEnded],
    );

    const visibleSeekPosition = seekingPosition || activeRecordingSeekPosition;

    return (
      <Flex flexGrow={[0, 6]} data-qa-id="audio-player-playhead" ml={[0, 4]}>
        <Box
          display={HIDE_ON_MOBILE}
          width={48}
          alignSelf="center"
          data-qa-id="audio-player-time-elapsed"
        >
          <TextLine colour={colours.elephant}>
            {formatRecordingTime(visibleSeekPosition)}
          </TextLine>
        </Box>
        <Box flexGrow={1} display={HIDE_ON_MOBILE}>
          <ProgressSlider
            data-qa-id="audio-player-progress"
            seekPosition={activeRecordingSeekPosition}
            duration={activeRecordingDuration}
            handleSeekingStarted={handleSeekRecordingStarted}
            handleSeekingEnded={handleSeekingEnded}
            handleSeeking={setSeekingPosition}
          />
        </Box>
        <Box
          display={HIDE_ON_MOBILE}
          width={48}
          alignSelf="center"
          data-qa-id="audio-player-time-total"
        >
          <TextLineRight colour={colours.elephant}>
            {formatRecordingTime(activeRecordingDuration)}
          </TextLineRight>
        </Box>
      </Flex>
    );
  },
);

const formatRecordingTime = (time: number = 0) => {
  time = Math.floor(time);
  const m = Math.floor(time / 60);
  const s = time % 60;
  return `${m < 10 ? '0' : ''}${m}:${s < 10 ? '0' : ''}${s}`;
};

const AudioPlayerBar: React.FC<AudioPlayerBarProps> = ({
  playingState,
  activeRecording,
  muted,
  volume,
  handleSeekRecordingStarted,
  handleSeekRecordingEnded,
  handlePlayRecording,
  handlePauseRecording,
  handleStopRecording,
  handlePlayNext,
  handlePlayPrev,
  handleToggleMute,
  handleVolumeChanged,
  handleVolumeSeek,
  isVisible = false,
  height = 64,
  maxWidth = 1280,
}) => {
  const activeRecordingDuration = activeRecording?.audioDuration || 1;
  const activeRecordingSeekPosition = activeRecording?.audioSeekPosition || 0;

  if (!isVisible) {
    return null;
  }

  return (
    <Box
      borderTop={`solid 1px ${colours.aquaHaze}`}
      data-qa-id="audio-player"
      position="fixed"
      bottom={0}
      width={1}
      bg={colours.white}
      zIndex={100}
    >
      <Box display={SHOW_ON_MOBILE}>
        <ProgressSlider
          isMobile={true}
          data-qa-id="audio-player-progress-mobile"
          seekPosition={activeRecordingSeekPosition}
          duration={activeRecordingDuration}
          handleSeekingStarted={handleSeekRecordingStarted}
          handleSeekingEnded={handleSeekRecordingEnded}
        />
      </Box>
      <Flex maxWidth={maxWidth} height={height} mx="auto">
        <Controls
          activeRecording={activeRecording}
          buttonPlayingState={playingState}
          handlePlayRecording={handlePlayRecording}
          handlePauseRecording={handlePauseRecording}
          handlePlayPrev={handlePlayPrev}
          handlePlayNext={handlePlayNext}
        />
        <Playhead
          activeRecordingDuration={activeRecordingDuration}
          activeRecordingSeekPosition={activeRecordingSeekPosition}
          handleSeekRecordingStarted={handleSeekRecordingStarted}
          handleSeekRecordingEnded={handleSeekRecordingEnded}
        />
        <Flex
          flexGrow={1}
          flexDirection="column"
          justifyContent="center"
          ml={[3, 3, 4]}
        >
          <Box data-qa-id="audio-player-title">
            <FullWidthTextLine ellipsis={true}>
              {activeRecording?.recordingTitle}
              {activeRecording?.versionTitle &&
                ` (${activeRecording?.versionTitle})`}
            </FullWidthTextLine>
          </Box>
          <Box data-qa-id="audio-player-artist">
            <FullWidthTextLine ellipsis={true} colour={colours.elephant}>
              {activeRecording?.artistName}
            </FullWidthTextLine>
          </Box>
        </Flex>

        <Box
          ml={[3, 3, 4]}
          alignSelf="center"
          data-qa-id="audio-player-recording-download"
          display={HIDE_ON_MOBILE}
        >
          {activeRecording && (
            <DownloadTrack
              uuid={activeRecording!.uuid}
              downloadUrl={activeRecording!.audio.downloadUrl}
            />
          )}
        </Box>
        <Flex flexDirection="row" ml={[0, 0, 4]}>
          <Box
            alignSelf="center"
            data-qa-id="audio-player-mute"
            mx="auto"
            display={HIDE_ON_TABLET}
          >
            <MuteButton muted={muted} handleClick={handleToggleMute} />
          </Box>
          <Box
            width={96}
            data-qa-id="audio-player-volume"
            ml={3}
            display={HIDE_ON_TABLET}
          >
            <VolumeSlider
              volume={volume}
              handleVolumeChanged={handleVolumeChanged}
              handleVolumeSeek={handleVolumeSeek}
            />
          </Box>
        </Flex>
        <Box alignSelf="center" ml={[3, 3, 4]} mr={26}>
          <CloseButton handleClick={handleStopRecording} />
        </Box>
      </Flex>
    </Box>
  );
};

export default AudioPlayerBar;
