import React from 'react';
import { colours, Explicit as ExplicitIcon } from '@ktech/kobalt-components';

import { Flex, Box, FullWidthTextLine } from '../../';
import createRecordingFilename from '../../../recording/createRecordingFilename';
import {
  PlayRecordingButton,
  AudioPlayerPlayingState,
} from '../../../audioPlayer';

import DownloadTrackLink from '../DownloadTrackLink';
import { trackTableRowWidths, TRACK_ROW_HEIGHT } from './constants';
import { HIDE_ON_MOBILE } from '../../theme';

export interface PromotionRecordingAudio {
  uuid: string;
  streamUrl: string;
  downloadUrl: string;
}

export interface PromotionRecording {
  uuid: string;
  recordingTitle: string;
  versionTitle?: string;
  isExplicit: boolean;
  hasAudio: boolean;
  audio?: PromotionRecordingAudio;
  artistName: string;
}

interface TrackListTableRowProps extends PromotionRecording {
  isActiveTrack: boolean;
  playingState: AudioPlayerPlayingState;
  handleOnClick: () => void;
}

const TrackListTableRow: React.FC<TrackListTableRowProps> = recording => {
  const {
    uuid,
    audio,
    isExplicit,
    hasAudio,
    recordingTitle,
    versionTitle,
    artistName,
    isActiveTrack,
    playingState,
    handleOnClick,
  } = recording;

  return (
    <Box key={uuid} width={1} data-qa-id={`promotion-recording-row-${uuid}`}>
      <Flex
        flexDirection="row"
        width={1}
        borderTop={`solid 1px ${colours.aquaHaze}`}
        alignItems="center"
        height={TRACK_ROW_HEIGHT}
      >
        <Box width={trackTableRowWidths.play} onClick={handleOnClick}>
          <Box ml={3}>
            {hasAudio && (
              <PlayRecordingButton
                data-qa-id={`promotion-recording-${uuid}-play`}
                highlightActiveTrack={true}
                isActiveTrack={isActiveTrack}
                playingState={playingState}
                handleOnClick={() => {}} // clicking on the row deals with this
              />
            )}
          </Box>
        </Box>
        {/* Inner flex needed for mobile  */}
        <Flex
          flexWrap={['wrap', 'nowrap']}
          alignItems="center"
          width={[1, 'auto']}
          onClick={handleOnClick}
          flexGrow={1}
        >
          <Box width={trackTableRowWidths.track} order={1}>
            <FullWidthTextLine
              ellipsis={true}
              data-qa-id={`promotion-recording-${uuid}-recording-title`}
            >
              {recordingTitle}
              {versionTitle && ` (${versionTitle})`}
            </FullWidthTextLine>
          </Box>
          <Box width={trackTableRowWidths.explicit} order={[3, 2]}>
            {isExplicit && (
              <div data-qa-id={`promotion-recording-${uuid}-explicit`}>
                <ExplicitIcon size={12} />
              </div>
            )}
          </Box>
          <Box width={trackTableRowWidths.artist} order={[2, 3]}>
            <FullWidthTextLine
              ellipsis={true}
              data-qa-id={`promotion-recording-${uuid}-artist-name`}
            >
              {artistName}
            </FullWidthTextLine>
          </Box>
        </Flex>
        <Box width={trackTableRowWidths.download} display={HIDE_ON_MOBILE}>
          {hasAudio && (
            <DownloadTrackLink uuid={uuid} downloadUrl={audio!.downloadUrl} />
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default React.memo(TrackListTableRow);
