import React from 'react';
import {
  colours,
  Pause as PauseIcon,
  Play as PlayIcon,
  Warning as WarningIcon,
  Loader as LoaderIcon,
  IconButton,
} from '@ktech/kobalt-components';
import { AudioPlayerPlayingState } from '../types';

const IconConfigMap = {
  [AudioPlayerPlayingState.error]: {
    component: WarningIcon,
    colour: colours.red,
  },
  [AudioPlayerPlayingState.playing]: {
    component: PauseIcon,
    colour: colours.teal,
  },
  [AudioPlayerPlayingState.loading]: {
    component: LoaderIcon,
    colour: colours.teal,
  },
  [AudioPlayerPlayingState.inactive]: {
    component: PlayIcon,
    colour: colours.teal,
  },
};

interface PlayRecordingButtonProps {
  [index: string]: any;
  highlightActiveTrack?: boolean;
  isActiveTrack: boolean;
  playingState: AudioPlayerPlayingState;
  handleOnClick: () => void;
  'data-qa-id'?: string;
  size?: number;
}

const PlayRecordingButton: React.FC<PlayRecordingButtonProps> = props => {
  const {
    'data-qa-id': qaId,
    isActiveTrack,
    playingState,
    handleOnClick,
    highlightActiveTrack,
    size = 20,
  } = props;

  const iconColour =
    isActiveTrack && highlightActiveTrack
      ? IconConfigMap[playingState].colour
      : colours.blue;

  const ButtonIcon = isActiveTrack
    ? IconConfigMap[playingState].component
    : PlayIcon;

  const qaState = isActiveTrack ? playingState : 'not-active';

  return (
    <IconButton onClick={handleOnClick} data-qa-id={`${qaId}-${qaState}`}>
      <ButtonIcon fill={iconColour} size={size} data-qa-id={`${qaId}-icon`} />
    </IconButton>
  );
};

export default PlayRecordingButton;
