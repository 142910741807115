import React from 'react';
import createUuid from 'uuid/v4';

import { KobaltTransparentLogo, PolicyFooter } from '../../components';
import {
  PromotionBackground,
  PromotionHeader,
  TrackContainer,
  LoadingPromotionTrackTable,
} from '../promotion/';

interface LoadingProps {
  uuid?: string;
  dataQaId?: string;
  showTrackRows?: boolean;
}

/*
 * Given the application is the single promotion page the loading
 * state is based on that screen to provide a consistent experiance to the user.
 * Loading therefore heavily uses the promotion components and includes a skeleton
 * version of the track rows.
 */
const Loading: React.FC<LoadingProps> = ({
  uuid = createUuid(),
  dataQaId = 'loading',
  showTrackRows = false,
}) => {
  return (
    <div data-qa-id={dataQaId}>
      <PromotionBackground uuid={uuid} />
      <KobaltTransparentLogo />
      <PromotionHeader />
      <TrackContainer>
        {showTrackRows && <LoadingPromotionTrackTable />}
      </TrackContainer>
      <PolicyFooter />
    </div>
  );
};

export default Loading;
