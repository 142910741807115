import { themes } from '@ktech/kobalt-components';

export const HIDE_ON_TABLET = ['none', 'none', 'block'];

export const HIDE_ON_MOBILE = ['none', 'block'];
export const SHOW_ON_MOBILE = ['block', 'none'];

const space: any = [0, 4, 8, 16, 32, 64, 128, 256, 512]; // 32 based

space.nudge = 1;
space.px = 1;
space.lg = 128;

// TODO: thinking
const sizes = {};

const shadows = {
  sm: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
  md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
  xl:
    '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
  '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
  outline: '0 0 0 3px rgba(66, 153, 225, 0.6)',
  inner: 'inset 0 2px 4px 0 rgba(0,0,0,0.06)',
  none: 'none',
};

const theme = {
  ...themes.syncTheme,
  colors: themes.syncTheme.colours,
  space,
  sizes,
  shadows,
};

theme.typography.h1.textTransform = 'none';
theme.typography.h2.textTransform = 'none';
theme.typography.h3.textTransform = 'none';

export default theme;
