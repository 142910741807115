import React from 'react';
import {
  Volume as VolumeIcon,
  VolumeOff as VolumeOffIcon,
  IconButton,
} from '@ktech/kobalt-components';
import { colours } from '@ktech/kobalt-components/dist/abstracts/colours';

export interface MuteButtonProps {
  muted: boolean;
  handleClick: () => void;
}

const iconProps = {
  fill: colours.blue,
  size: 18,
};

const MuteButton: React.FC<MuteButtonProps> = ({ muted, handleClick }) => {
  let icon = (
    <div data-qa-id="audio-player-unmuted">
      <VolumeIcon {...iconProps} />
    </div>
  );

  if (muted) {
    icon = (
      <div data-qa-id="audio-player-muted">
        <VolumeOffIcon {...iconProps} />
      </div>
    );
  }

  return (
    <IconButton onClick={handleClick} data-qa-id="audio-player-mute-button">
      {icon}
    </IconButton>
  );
};

export default React.memo(MuteButton);
