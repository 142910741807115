import React from 'react';

import { Flex, Box } from '../layout/index';
import PolicyLink from './PolicyLink';

export default React.memo(() => (
  <Flex>
    <Box mx="auto" py={4}>
      <PolicyLink
        href="https://www.kobaltmusic.com/legal/privacy-policy"
        text="Privacy Policy"
      />
      <PolicyLink
        href="https://www.kobaltmusic.com/legal/cookies-policy"
        text="Cookies Policy"
      />
    </Box>
  </Flex>
));
