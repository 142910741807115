import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from '@ktech/kobalt-components';

import { TrackingProvider } from './components/tracking';
import theme from './components/theme';
import client from './apollo/client';
import trackEventWithMixpanel, { TrackingEventData } from './tracking';

const Providers: React.FC = ({ children }) => (
  <TrackingProvider<TrackingEventData> trackEvent={trackEventWithMixpanel}>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </ThemeProvider>
  </TrackingProvider>
);

export default Providers;
