import React from 'react';
import CookieConsent from 'react-cookie-consent';

import { colours } from '@ktech/kobalt-components';

export default () => (
  <CookieConsent
    location="top"
    buttonText="GOT IT"
    cookieName="spdtCookieConsent"
    style={{ background: colours.russian, justifyContent: 'center' }}
    contentStyle={{ flex: null }}
    buttonId="cookie-consent-dismiss-button"
    buttonStyle={{
      background: colours.red,
      color: colours.white,
      borderRadius: '23px',
      fontSize: '14px',
      fontWeight: 'bold',
      padding: '10px 15px',
    }}
  >
    We use cookies to ensure that we give you the best experience on our
    website.{' '}
    <a
      href="https://www.kobaltmusic.com/legal/cookies-policy"
      target="_blank"
      style={{
        color: colours.white,
        padding: '10px 15px',
      }}
    >
      More info
    </a>
  </CookieConsent>
);
