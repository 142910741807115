import styled from 'styled-components';
import {
  background,
  BackgroundProps as IBackgroundProps,
  position,
  PositionProps,
} from 'styled-system';

import { Box } from '../layout';

interface BackgroundProps extends IBackgroundProps, PositionProps {}

export default styled(Box)<BackgroundProps>(background, position);
