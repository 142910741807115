import React from 'react';
import { colours, TextLine } from '@ktech/kobalt-components';

import { Flex, Box } from '../../layout';
import { HIDE_ON_MOBILE } from '../../theme';

import { trackTableRowWidths, TRACK_ROW_HEIGHT } from './constants';

const TrackListTableHeaders = () => {
  return (
    <Box width={1} display={HIDE_ON_MOBILE}>
      <Flex
        flexDirection="row"
        width={1}
        alignItems="center"
        height={TRACK_ROW_HEIGHT}
      >
        <Box width={trackTableRowWidths.play} />
        <Box width={trackTableRowWidths.artist}>
          <TextLine colour={colours.elephant}>Track</TextLine>
        </Box>
        <Box width={trackTableRowWidths.explicit} ml={2} />
        <Box width={trackTableRowWidths.artist}>
          <TextLine colour={colours.elephant}>Artist</TextLine>
        </Box>
        <Box width={trackTableRowWidths.download} />
      </Flex>
    </Box>
  );
};

export default React.memo(TrackListTableHeaders);
