import React from 'react';
import { Next as NextIcon, IconButton } from '@ktech/kobalt-components';
import { colours } from '@ktech/kobalt-components/dist/abstracts/colours';
import { ClickableButtonProps } from './types/ClickableButtonProps';

const NextButton: React.FC<ClickableButtonProps> = ({
  handleClick,
  isDisabled,
}) => (
  <IconButton
    onClick={handleClick}
    data-qa-id="audio-player-forward"
    disabled={isDisabled}
  >
    <NextIcon fill={isDisabled ? colours.silver : colours.navy} size={16} />
  </IconButton>
);

export default React.memo(NextButton);
