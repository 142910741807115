import React from 'react';
import { Box } from '../layout';

const TrackContainer: React.FC = ({ children }) => {
  return (
    <Box
      mx="auto"
      maxWidth={1024}
      bg="white"
      borderRadius={4}
      boxShadow="lg"
      minHeight={512}
    >
      {children}
    </Box>
  );
};

export default TrackContainer;
