import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { ApolloClient } from 'apollo-client';

export default new ApolloClient({
  link: new HttpLink({
    credentials: 'same-origin',
  }),
  cache: new InMemoryCache({
    dataIdFromObject: (obj: any) =>
      `${obj.__typename}.${obj.uuid || obj.databaseId || null}`,
  }),
});
