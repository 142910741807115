import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import Providers from './Providers';
import fetchConfig from './fetchConfig';
import logger from './logger';
import { mixpanel } from './tracking';

fetchConfig().then(config => {
  logger.init(config);
  mixpanel.init(config.mixpanelApiKey);

  ReactDOM.render(
    <Providers>
      <App />
    </Providers>,
    document.getElementById('root'),
  );
});
