import React, { useCallback } from 'react';
import useInterval from '@use-it/interval';

import howlerPlayer from './howler';
import {
  AudioPlayerState,
  AudioPlayerActionTypes,
  AudioPlayerActions,
} from './audioPlayerReducer';
import { AudioPlayerRecording, AudioPlayerInternalState } from './types';
import AudioPlayerContext from './AudioPlayerContext';

interface IAudioPlayerProviderProps {
  state: AudioPlayerState;
  dispatch: React.Dispatch<AudioPlayerActions>;
  trackEvent?: (name: string, data: {}) => void;
  previousRecording?: AudioPlayerRecording;
  nextRecording?: AudioPlayerRecording;
}

const AudioPlayerProvider: React.FC<IAudioPlayerProviderProps> = ({
  children,
  state: { playerState, activeRecording, howlId, muted, volume },
  dispatch,
  previousRecording,
  nextRecording,
  trackEvent = () => {},
}) => {
  // Used to control interval
  const [isTrackingPlayback, setIsTrackingPlayback] = React.useState(false);

  // Been muted set Volume to 0
  React.useEffect(() => {
    howlerPlayer.setVolume(muted ? 0 : volume);
  }, [muted, volume]);

  // This should never happen, but in the types don't enforce that
  // side effect of not having recording and playing state in a single object
  // however modeling the player states in that way doesn't really match well to the
  // functionality
  const uuid = activeRecording?.uuid ?? 'unset';

  // don't expose the stored value from before being muted
  const volumeGivenMute = muted ? 0 : volume;

  const handlePlayNextRecording = nextRecording
    ? () => {
        handlePlayRecording(nextRecording);
      }
    : undefined;

  const handlePlayPreviousRecording = previousRecording
    ? () => {
        if (activeRecording && (activeRecording?.audioSeekPosition ?? 0) > 5) {
          handleSeekRecordingEnded(0);
        } else {
          handlePlayRecording(previousRecording);
        }
      }
    : undefined;

  // If the howler id has updated attach listener for next track
  // You can't do this using the onEnd callback as you don't
  // know the next track at the point of playing
  React.useEffect(() => {
    if (howlId) {
      howlerPlayer.on(howlId, 'end', () => {
        setIsTrackingPlayback(false);
        dispatch({
          type: AudioPlayerActionTypes.played,
        });

        if (handlePlayNextRecording) {
          handlePlayNextRecording();
        }
      });
    }
  }, [howlId]);

  useInterval(
    () => {
      if (howlId) {
        dispatch({
          type: AudioPlayerActionTypes.playingProgress,
          payload: {
            audioSeekPosition: Math.ceil(howlerPlayer.getCurrentTime(howlId)),
          },
        });
      }
    },
    isTrackingPlayback ? 500 : null,
  );

  const handleStopRecording = useCallback(() => {
    if (howlId) {
      setIsTrackingPlayback(false);
      howlerPlayer.stop(howlId);
      dispatch({
        type: AudioPlayerActionTypes.stop,
      });
      trackEvent('AudioPlayerRecordingStopped', {
        recordingUuid: uuid,
      });
    }
  }, [howlId, uuid]);

  const handleSeekRecordingEnded = useCallback(
    (position: number) => {
      if (howlId) {
        howlerPlayer.seek(howlId, position);
        dispatch({
          type: AudioPlayerActionTypes.playingProgress,
          payload: {
            audioSeekPosition: position,
          },
        });

        if (playerState === AudioPlayerInternalState.playing) {
          setIsTrackingPlayback(true);
        }

        trackEvent('AudioPlayerRecordingSeek', {
          recordingUuid: uuid,
          seekPosition: position,
        });
      }
    },
    [howlId, uuid, playerState],
  );

  const handlePlayRecording = useCallback(
    (recording: AudioPlayerRecording) => {
      if (!recording) {
        return;
      }

      if (!activeRecording || recording.uuid !== activeRecording.uuid) {
        // regardless of the state of the other track stop it
        setIsTrackingPlayback(false);
        if (howlId) {
          howlerPlayer.stop(howlId);
        }

        dispatch({
          type: AudioPlayerActionTypes.play,
          payload: { recording },
        });

        howlerPlayer.play(recording.audio.streamUrl, {
          onPlay: newHowlId => {
            dispatch({
              type: AudioPlayerActionTypes.playing,
              payload: {
                howlId: newHowlId,
                audioDuration: Math.ceil(howlerPlayer.getDuration(newHowlId)),
              },
            });
            setIsTrackingPlayback(true);
            trackEvent('AudioPlayerRecordingPlaying', {
              recordingUuid: uuid,
            });
          },
          onPause: () => {
            setIsTrackingPlayback(false);
            dispatch({
              type: AudioPlayerActionTypes.paused,
            });
            trackEvent('AudioPlayerRecordingPaused', {
              recordingUuid: uuid,
            });
          },
          onLoadError: () => {
            setIsTrackingPlayback(false);
            dispatch({
              type: AudioPlayerActionTypes.errored,
            });
          },
          onPlayError: () => {
            setIsTrackingPlayback(false);
            dispatch({
              type: AudioPlayerActionTypes.errored,
            });
          },
        });
      } else {
        howlerPlayer.resume(howlId as number);
        setIsTrackingPlayback(true);
        trackEvent('AudioPlayerRecordingResume', {
          recordingUuid: uuid,
        });
      }
    },
    [activeRecording, howlId, uuid],
  );

  const handlePauseRecording = useCallback(() => {
    if (!howlId) {
      return;
    }

    dispatch({
      type: AudioPlayerActionTypes.pause,
    });
    howlerPlayer.pause(howlId);
    setIsTrackingPlayback(false);
    // no trackEvent here as we fire it
    // from the howler callback
  }, [howlId]);

  const handleToggleMute = useCallback(() => {
    dispatch({
      type: AudioPlayerActionTypes.muteToggled,
    });
    trackEvent('AudioPlayerToggleMute', {
      recordingUuid: uuid,
    });
  }, [uuid]);

  const handleVolumeChanged = useCallback(
    (volume: number) => {
      dispatch({
        type: AudioPlayerActionTypes.volumeChanged,
        payload: { volume },
      });
      trackEvent('AudioPlayerVolumeChanged', {
        recordingUuid: uuid,
        volume,
      });
    },
    [muted, uuid],
  );

  const handleVolumeSeek = useCallback((volume: number) => {
    howlerPlayer.setVolume(volume);
  }, []);

  return (
    <AudioPlayerContext.Provider
      value={{
        playerState,
        activeRecording,
        muted,
        volume: volumeGivenMute,
        handlePlayRecording,
        handlePlayNextRecording,
        handlePlayPreviousRecording,
        handlePauseRecording,
        handleSeekRecordingStarted: () => {
          setIsTrackingPlayback(false);
        },
        handleSeekRecordingEnded,
        handleStopRecording,
        handleToggleMute,
        handleVolumeChanged,
        handleVolumeSeek,
      }}
    >
      {children}
    </AudioPlayerContext.Provider>
  );
};

export default AudioPlayerProvider;
