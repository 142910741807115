import React from 'react';
import {
  Button,
  Loader,
  colours,
  Warning as WarningIcon,
  Download as DownloadIcon,
} from '@ktech/kobalt-components';

import { Flex, Box } from '../layout';
import { useTracking } from '../tracking';
import { TrackingEventData, TrackingData } from '../../tracking/types';

interface DownloadAllTracksButtonProps {
  downloadUrl: string;
}

const DownloadAllTracksButton: React.FC<DownloadAllTracksButtonProps> = ({
  downloadUrl,
}) => {
  const [{ trackEvent }] = useTracking<TrackingData, TrackingEventData>();

  return (
    <a href={downloadUrl} download>
      <Button
        data-qa-id="download-all-button"
        rounded={true}
        buttonSize="small"
        onClick={() => {
          trackEvent({
            eventType: 'track',
            eventName: 'PromotionDownloadAll',
          });
        }}
      >
        <Flex>
          <Box pr={2}>Download all tracks</Box>
          <DownloadIcon size={16} fill={colours.port} />
        </Flex>
      </Button>
    </a>
  );
};

export default DownloadAllTracksButton;
