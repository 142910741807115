import React from 'react';

import { AudioPlayerBar, useAudioPlayer } from '../../audioPlayer';

const PromotionAudioPlayer: React.FC = () => {
  const {
    playerState,
    activeRecording,
    audioPlayerVisible,
    muted,
    volume,
    handleSeekRecordingStarted,
    handleSeekRecordingEnded,
    handlePauseRecording,
    handlePlayRecording,
    handlePlayNextRecording,
    handlePlayPreviousRecording,
    handleStopRecording,
    handleToggleMute,
    handleVolumeChanged,
    handleVolumeSeek,
  } = useAudioPlayer();

  return (
    <AudioPlayerBar
      isVisible={audioPlayerVisible}
      playingState={playerState}
      activeRecording={activeRecording}
      muted={muted}
      volume={volume}
      handleSeekRecordingStarted={handleSeekRecordingStarted}
      handleSeekRecordingEnded={handleSeekRecordingEnded}
      handlePauseRecording={handlePauseRecording}
      handlePlayRecording={handlePlayRecording}
      handleStopRecording={handleStopRecording}
      handlePlayNext={handlePlayNextRecording}
      handlePlayPrev={handlePlayPreviousRecording}
      handleToggleMute={handleToggleMute}
      handleVolumeChanged={handleVolumeChanged}
      handleVolumeSeek={handleVolumeSeek}
    />
  );
};

export default PromotionAudioPlayer;
