import React from 'react';

import {
  AudioPlayerRecording,
  AudioPlayerInternalState,
  AudioPlayerActiveRecording,
} from './types';

export interface IAudioPlayerContext {
  playerState: AudioPlayerInternalState;
  volume: number;
  muted: boolean;
  activeRecording?: AudioPlayerActiveRecording;
  handlePlayRecording: (recording: AudioPlayerRecording) => void;
  handlePlayNextRecording?: () => void;
  handlePlayPreviousRecording?: () => void;
  handleSeekRecordingStarted: () => void;
  handleSeekRecordingEnded: (position: number) => void;
  handlePauseRecording: () => void;
  handleStopRecording: () => void;
  handleToggleMute: () => void;
  handleVolumeChanged: (volume: number) => void;
  handleVolumeSeek: (volume: number) => void;
}

// No op the methods which are
export default React.createContext<IAudioPlayerContext>({
  playerState: AudioPlayerInternalState.stopped,
  activeRecording: undefined,
  volume: 100,
  muted: false,
  handlePlayRecording: () => {},
  handleSeekRecordingStarted: () => {},
  handleSeekRecordingEnded: () => {},
  handlePauseRecording: () => {},
  handleStopRecording: () => {},
  handleToggleMute: () => {},
  handleVolumeChanged: () => {},
  handleVolumeSeek: () => {},
});
